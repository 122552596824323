'use strict';

var addressHelpers = require('./address');
var formHelpers = require('base/checkout/formErrors');
var scrollAnimate = require('base/components/scrollAnimate');

var countrySelectHelper = require('../components/localePreferences');

var base = require('base/checkout/shipping');
addressHelpers.init('shipping');

/**
 * updates the shipping address selector within shipping forms
 * @param {Object} form - form
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} customer - the customer model
 */
function updateShippingAddressSelector(form, shipping, order, customer) {
    var shippings = order.shipping;
    var $shippingAddressSelector = $('.addressSelector', form);
    var hasSelectedAddress = false;

    if ($shippingAddressSelector && $shippingAddressSelector.length === 1) {
        $shippingAddressSelector.empty();
        // Add New Address option
        $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            null,
            false,
            order
        ));

        if (customer.addresses && customer.addresses.length > 0) {
            $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
                order.resources.accountAddresses,
                false,
                order
            ));

            customer.addresses.forEach(function (address) {
                var isSelected = shipping.matchingAddressId === address.ID;
                $shippingAddressSelector.append(
                    addressHelpers.methods.optionValueForAddress(
                        { UUID: 'ab_' + address.ID, shippingAddress: address },
                        isSelected,
                        order
                    )
                );
            });
        }
        // Separator -
        if (!order.shipping[0].fromStoreId) {
            $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
                order.resources.shippingAddresses, false, order, { className: 'multi-shipping' }
            ));
        }
        shippings.forEach(function (aShipping) {
            var isSelected = shipping.UUID === aShipping.UUID;
            if (!order.shipping[0].fromStoreId) {
                hasSelectedAddress = isSelected;
                var addressOption = addressHelpers.methods.optionValueForAddress(
                    aShipping,
                    isSelected,
                    order,
                    { className: 'multi-shipping' }
                );
                var newAddress = addressOption.html() === order.resources.addNewAddress;
                var matchingUUID = aShipping.UUID === shipping.UUID;
                if ((newAddress && matchingUUID) || (!newAddress && matchingUUID) || (!newAddress && !matchingUUID)) {
                    $shippingAddressSelector.append(addressOption);
                }
                if (newAddress && !matchingUUID) {
                    $(addressOption[0]).remove();
                }
            }
        });
    }

    $(form).toggleClass('hide-details', hasSelectedAddress);
}

/**
 * overwritten to hide in store pickup
 * updates the shipping method radio buttons within shipping forms
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateShippingMethods(shipping) {
    var uuidEl = $('input[value=' + shipping.UUID + ']');
    if (uuidEl && uuidEl.length > 0) {
        $.each(uuidEl, function (shipmentIndex, el) {
            var form = el.form;
            if (!form) return;

            var $shippingMethodList = $('.shipping-method-list', form);

            if ($shippingMethodList && $shippingMethodList.length > 0) {
                $shippingMethodList.empty();
                var shippingMethods = shipping.applicableShippingMethods;
                var selected = shipping.selectedShippingMethod || {};
                var shippingMethodFormID = form.name + '_shippingAddress_shippingMethodID';
                //
                // Create the new rows for each shipping method
                //
                $.each(shippingMethods, function (methodIndex, shippingMethod) {
                    var tmpl = $('#shipping-method-template').clone();
                    // set input

                    if (shippingMethod.storePickupEnabled || shippingMethod.onlineGiftCard) {
                        $('.col-12', tmpl).addClass('d-none');
                    }

                    $('input', tmpl)
                        .prop('id', 'shippingMethod-' + shippingMethod.ID + '-' + shipping.UUID)
                        .prop('name', shippingMethodFormID)
                        .prop('value', shippingMethod.ID)
                        .attr('checked', shippingMethod.ID === selected.ID)
                        .attr('data-pickup', shippingMethod.storePickupEnabled);

                    $('label', tmpl)
                        .prop('for', 'shippingMethod-' + shippingMethod.ID + '-' + shipping.UUID);
                    // set shipping method name
                    $('.display-name', tmpl).text(shippingMethod.displayName);
                    // set or hide arrival time
                    if (shippingMethod.estimatedArrivalTime) {
                        $('.arrival-time', tmpl)
                            .text('(' + shippingMethod.estimatedArrivalTime + ')')
                            .show();
                    }
                    // set shipping cost
                    $('.shipping-cost', tmpl).text(shippingMethod.shippingCost);
                    $shippingMethodList.append(tmpl.html());
                });
            }
        });
    }

    $('body').trigger('shipping:updateShippingMethods', { shipping: shipping });
}

/**
 * updates the shipping address selector within shipping forms
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} customer - the customer model
 */
function updateSingleShippingAddressSelector(shipping, order, customer) {
    var form = $('.single-shipping .shipping-form');

    updateShippingAddressSelector(form, shipping, order, customer);

    $('body').trigger('shipping:updateShippingAddressSelector', {
        shipping: shipping,
        order: order,
        customer: customer
    });
}

/**
 * updates the shipping address selector within shipping forms
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} customer - the customer model
 */
function updateMultiShippingAddressSelector(productLineItem, shipping, order, customer) {
    var uuidEl = $('input[value=' + productLineItem.UUID + ']');

    if (uuidEl && uuidEl.length > 0) {
        updateShippingAddressSelector(uuidEl[0].form, shipping, order, customer);

        $('body').trigger('shipping:updateShippingAddressSelector', {
            productLineItem: productLineItem,
            shipping: shipping,
            order: order,
            customer: customer
        });
    }
}

/**
 * updates layout shipping address form
 * @param {Object} addressObject - the address object
 * @param {Object} targetedForm - the form to set the layout off
 */
function setLayout(addressObject, targetedForm) {
    var form = targetedForm || false;
    var isCompany = !!addressObject.companyName;
    isCompany = addressObject.isCompany ? addressObject.isCompany : isCompany;

    var checkbox = form ? $('.js-shipping-company-checkbox', form) : $('.js-shipping-company-checkbox');

    checkbox.prop('checked', isCompany);

    const companyInfo = checkbox.closest('.js-company-info');
    companyInfo.find('.js-company-fields').toggleClass('d-none', !isCompany);

    companyInfo.find('.js-company-name').prop('required', isCompany);
    companyInfo.find('.js-company-name').attr('aria-required', isCompany.toString());
}

/**
 * updates the shipping address form values within shipping forms
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateShippingAddressFormValues(shipping) {
    var addressObject = $.extend({}, shipping.shippingAddress);

    if (!addressObject) {
        addressObject = {
            vat: null,
            companyName: null,
            suite: null,
            firstName: null,
            lastName: null,
            address1: null,
            address2: null,
            city: null,
            postalCode: null,
            stateCode: null,
            countryCode: null,
            phone: null
        };
    }

    addressObject.valisette = shipping.valisette;
    addressObject.isGift = shipping.isGift;
    addressObject.giftSender = shipping.giftSender;
    addressObject.giftMessage = shipping.giftMessage;

    $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
        var form = el.form;
        if (!form) return;
        var countryCode = addressObject.countryCode;

        setLayout(addressObject, form);

        $('input[name$=_companyName]', form).val(addressObject.companyName);
        $('input[name$=_vat]', form).val(addressObject.vat);
        $('input[name$=_suite]', form).val(addressObject.suite);

        $('input[name$=_firstName]', form).val(addressObject.firstName);
        $('input[name$=_lastName]', form).val(addressObject.lastName);
        $('input[name$=_address1]', form).val(addressObject.address1);
        $('input[name$=_address2]', form).val(addressObject.address2);
        $('input[name$=_city]', form).val(addressObject.city);
        $('input[name$=_postalCode]', form).val(addressObject.postalCode);
        $('select[name$=_stateCode],input[name$=_stateCode]', form)
        .val(addressObject.stateCode);

        if (countryCode && typeof countryCode === 'object') {
            $('select[name$=_country]', form).val(addressObject.countryCode.value);
        } else {
            $('select[name$=_country]', form).val(addressObject.countryCode);
        }

        $('input[name$=_phone]', form).val(addressObject.phone);

        $('input[name$=_valisette]', form).prop('checked', addressObject.valisette);
        $('input[name$=_isGift]', form).prop('checked', addressObject.isGift);
        $('input[name$=_giftSender]', form).val(addressObject.isGift && addressObject.giftSender ? addressObject.giftSender : '');
        $('textarea[name$=_giftMessage]', form).val(addressObject.isGift && addressObject.giftMessage ? addressObject.giftMessage : '');
    });

    $('body').trigger('shipping:updateShippingAddressFormValues', { shipping: shipping });
}

/**
 * Update list of available shipping methods whenever user modifies shipping address details.
 * @param {jQuery} $shippingForm - current shipping form
 */
function updateShippingMethodList($shippingForm) {
    // delay for autocomplete!
    setTimeout(function () {
        var $shippingMethodList = $shippingForm.find('.shipping-method-list');
        var urlParams = addressHelpers.methods.getAddressFieldsFromUI($shippingForm);
        var shipmentUUID = $shippingForm.find('[name=shipmentUUID]').val();
        var url = $shippingMethodList.data('actionUrl'); // CheckoutShippingServices-UpdateShippingMethodsList
        urlParams.shipmentUUID = shipmentUUID;


        if ($shippingMethodList.data('requestRunning')) {
            return;
        }

        $shippingMethodList.data('requestRunning', true);

        $shippingMethodList.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('body').trigger('checkout:updateCheckoutView',
                        {
                            order: data.order,
                            customer: data.customer,
                            options: { keepOpen: true }
                        });

                    // remove d-none from the shippingmethodlist element once a state has been selected
                    var $shippingBlock = $shippingForm.find('.shipping-method-block');
                    if (!$shippingBlock.data('online-gift-card')) {
                        $shippingBlock.removeClass('d-none');
                    }

                    const ukMessageElement = $('.order-total-summary .js-order-uk-message');
                    ukMessageElement.toggleClass('d-none', !data.ukPriceLimitExceeded)
                                    .toggleClass('d-flex', data.ukPriceLimitExceeded);

                    $shippingForm.find('.gift-message-block').removeClass('d-none');
                    $('.submit-shipping').removeClass('d-none');
                    // $shippingForm.find('.brexit-collection-shipping').addClass('d-none');

                    $('.js-tbd-shipping-cost, .js-merch-total').addClass('d-none');
                    $('.js-checkout-shipping-cost, .js-checkout-grand-total, .js-checkout-shipping-discount').removeClass('d-none');

                    $shippingMethodList.spinner().stop();
                }
            },
            complete: function () {
                $shippingMethodList.data('requestRunning', false);
                setTimeout(function () {
                    $('.js-noShippingMethodsAlert').toggleClass('d-none', !!$('.shipping-method-list input:visible').length);
                }, 1000);
                $('.shipping-method-list').find('.form-check-input.custom-control-input:visible').first().trigger('click');
            }
        });
    }, 300);
}

/**
 * updates the order shipping summary for an order shipment model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 */
function updateShippingSummaryInformation(shipping, order) {
    $.each(shipping.shippingAddress, function (attr) {
        if (shipping.shippingAddress[attr] === 'undefined') shipping.shippingAddress[attr] = null;
    });
    base.methods.updateShippingSummaryInformation(shipping, order);
    const $container = $(`[data-shipment-summary=${shipping.UUID}]`);

    if (shipping.isGift) {
        $('.gift-summary .gift-sender-summary', $container)
            .text(shipping.giftSender)
            .parent().toggleClass('d-none', !shipping.giftSender);
    }
    $('#shippingAddressUseAsBillingAddress').parent().toggleClass('d-none', !!shipping.fromStoreId);
    $('.pickup-contact').toggleClass('d-none', !shipping.fromStoreId);
    $('.js-shipping-bussiness').toggleClass('d-none', !!shipping.fromStoreId);
    if (shipping.fromStoreId) {
        $('.pickup-contact .pickup-contact-name', $container).text(shipping.pickupContactFirstName + ' ' + shipping.pickupContactLastName);
        $('.pickup-contact .pickup-contact-number', $container).text(shipping.pickupContactNumber);
    }
}

/**
 * Update the read-only portion of the shipment display (per PLI)
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} [options] - options for updating PLI summary info
 * @param {Object} [options.keepOpen] - if true, prevent changing PLI view mode to 'view'
 */
function updatePLIShippingSummaryInformation(productLineItem, shipping, order, options) {
    base.methods.updatePLIShippingSummaryInformation(productLineItem, shipping, order, options);

    const form = $(`input[value=${productLineItem.UUID}]`).prop('form');

    if (!form) {
        return;
    }

    const address = shipping.shippingAddress || {};
    const $viewBlock = $('.view-address-block', form);

    $('.ship-to-company-name', $viewBlock).text(address.companyName);

    if (shipping.isGift) {
        $('.gift-sender-summary', $viewBlock)
            .text(shipping.giftSender)
            .parent().toggleClass('d-none', !shipping.giftSender);
    }
}

/**
 * Update the hidden form values that associate shipping info with product line items
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateProductLineItemShipmentUUIDs(productLineItem, shipping) {
    const { UUID, shippingAddress } = shipping;

    $(`input[value=${productLineItem.UUID}]`).each((key, { form }) => {
        $('[name=shipmentUUID]', form).val(UUID);
        $('[name=originalShipmentUUID]', form).val(UUID);
        if (shippingAddress && shippingAddress.originalAddressID) {
            $('[name=originalAddressID]', form).val(shippingAddress.originalAddressID);
        } else {
            $('[name=originalAddressID]', form).val('');
        }

        $(form).closest('.card').attr('data-shipment-uuid', UUID);
    });

    $('body').trigger('shipping:updateProductLineItemShipmentUUIDs', {
        productLineItem: productLineItem,
        shipping: shipping
    });
}

/**
 * Update the shipping UI for a single shipping info (shipment model)
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order/basket model
 * @param {Object} customer - the customer model
 * @param {Object} [options] - options for updating PLI summary info
 * @param {Object} [options.keepOpen] - if true, prevent changing PLI view mode to 'view'
 */
function updateShippingInformation(shipping, order, customer, options) {
    // First copy over shipmentUUIDs from response, to each PLI form
    order.shipping.forEach(function (aShipping) {
        aShipping.productLineItems.items.forEach(function (productLineItem) {
            updateProductLineItemShipmentUUIDs(productLineItem, aShipping);
        });
    });
    // Now update shipping information, based on those associations
    updateShippingMethods(shipping);
    updateShippingAddressFormValues(shipping);
    updateShippingSummaryInformation(shipping, order);
    updateSingleShippingAddressSelector(shipping, order, customer);

    // And update the PLI-based summary information as well
    shipping.productLineItems.items.forEach(function (productLineItem) {
        updateMultiShippingAddressSelector(productLineItem, shipping, order, customer);
        updatePLIShippingSummaryInformation(productLineItem, shipping, order, options);
    });

    $('body').trigger('shipping:updateShippingInformation', {
        order: order,
        shipping: shipping,
        customer: customer,
        options: options
    });
}

/**
 * Handle response from the server for valid or invalid form fields.
 * @param {Object} defer - the deferred object which will resolve on success or reject.
 * @param {Object} data - the response data with the invalid form fields or
 *  valid model data.
 */
function shippingFormResponse(defer, data) {
    var isMultiShip = $('#checkout-main').hasClass('multi-ship');
    var formSelector = isMultiShip
        ? '.multi-shipping .active form'
        : '.single-shipping form';
    // highlight fields with errors
    if (data.error) {
        if (data.fieldErrors.length) {
            data.fieldErrors.forEach(function (error) {
                if (Object.keys(error).length) {
                    formHelpers.loadFormErrors(formSelector, error);
                }
            });
            $(formSelector).find('.btn-show-details').trigger('click');
            defer.reject(data);
        }

        if (data.serverErrors && data.serverErrors.length) {
            $.each(data.serverErrors, function (index, element) {
                base.methods.createErrorNotification(element);
            });

            defer.reject(data);
        }

        if (data.cartError) {
            window.location.href = data.redirectUrl;
            defer.reject();
        }
    } else {
        // Populate the Address Summary
        $('body').trigger('checkout:updateCheckoutView', {
            order: data.order,
            customer: data.customer
        });
        scrollAnimate($('.payment-form'));
        defer.resolve(data);
    }
}
/**
 * Clear out all the shipping form values and select the new address in the drop down
 * @param {Object} order - the order object
 */
function clearShippingForms(order) {
    order.shipping.forEach(function (shipping) {
        $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
            var form = el.form;
            if (!form) return;

            $('input[name$=_companyName]', form).val('');
            $('input[name$=_vat]', form).val('');
            $('input[name$=_suite]', form).val('');
            $('input[name$=_giftSender]', form).val('');

            $('input[name$=_firstName]', form).val('');
            $('input[name$=_lastName]', form).val('');
            $('input[name$=_address1]', form).val('');
            $('input[name$=_address2]', form).val('');
            $('input[name$=_city]', form).val('');
            $('input[name$=_postalCode]', form).val('');
            $('select[name$=_stateCode],input[name$=_stateCode]', form).val('');
            $('select[name$=_country]', form).val('');

            $('input[name$=_phone]', form).val('');

            $('input[name$=_valisette]', form).prop('checked', false);
            $('input[name$=_isGift]', form).prop('checked', false);
            $('textarea[name$=_giftMessage]', form).val('');
            $(form).find('.gift-message').addClass('d-none');

            $(form).attr('data-address-mode', 'new');
            var addressSelectorDropDown = $('.addressSelector option[value=new]', form);
            $(addressSelectorDropDown).prop('selected', true);
        });
    });

    $('body').trigger('shipping:clearShippingForms', { order: order });
}

/**
 * perform the proper actions once a user has clicked enter address or edit address for a shipment
 * @param {jQuery} element - The shipping content
 * @param {string} mode - the address mode
 */
function editOrEnterMultiShipInfo(element, mode) {
    var form = $(element).closest('form');
    var root = $(element).closest('.shipping-content');

    $('body').trigger('shipping:updateDataAddressMode', { form: form, mode: mode });

    base.methods.editMultiShipAddress(root);

    var addressInfo = addressHelpers.methods.getAddressFieldsFromUI(form);

    var savedState = {
        UUID: $('input[name=shipmentUUID]', form).val(),
        shippingAddress: addressInfo
    };

    root.data('saved-state', JSON.stringify(savedState));
}

module.exports = {
    methods: {
        updateSingleShippingAddressSelector: updateSingleShippingAddressSelector,
        updateMultiShippingAddressSelector: updateMultiShippingAddressSelector,
        updateShippingAddressFormValues: updateShippingAddressFormValues,
        updateShippingMethods: updateShippingMethods,
        updateShippingSummaryInformation: updateShippingSummaryInformation,
        updatePLIShippingSummaryInformation: updatePLIShippingSummaryInformation,
        updateProductLineItemShipmentUUIDs: updateProductLineItemShipmentUUIDs,
        updateShippingInformation: updateShippingInformation,
        updateMultiShipInformation: base.methods.updateMultiShipInformation,
        shippingFormResponse: shippingFormResponse,
        createNewShipment: base.methods.createNewShipment,
        selectShippingMethodAjax: base.methods.selectShippingMethodAjax,
        updateShippingMethodList: updateShippingMethodList,
        clearShippingForms: clearShippingForms,
        editMultiShipAddress: base.methods.editMultiShipAddress,
        editOrEnterMultiShipInfo: editOrEnterMultiShipInfo,
        createErrorNotification: base.methods.createErrorNotification,
        viewMultishipAddress: base.methods.viewMultishipAddress
    },

    editOnlineVoucherShipment: function () {
        $('.btn-edit-online-voucher').on('click', function (e) {
            e.preventDefault();

            var parent = $(this).closest('.evoucher-block');
            parent.find('.evoucher-shipping-address').removeClass('d-none');
            parent.find('.multi-shipping-summary').addClass('d-none');
        });
    },

    saveSingleOnlineVoucherShipment: function () {
        $('.btn-save-online-voucher').on('click', function (e) {
            e.preventDefault();
            $.spinner().start();

            var form = $(this).closest('form');

            $.ajax({
                url: form.attr('action'), // checkoutShippingServices-SaveOnlineVoucher
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();

                    // update summary display
                    var invalidName = form.find('input[name^="voucherTo-"]').siblings('.invalid-feedback');
                    invalidName.toggleClass('d-block', !data.recipientName.valid);
                    if (data.recipientName.valid) {
                        form.find('.js-recipient-name').text(data.recipientName.value);
                    } else {
                        invalidName.text(data.recipientName.value);
                    }

                    var invalidMail = form.find('input[name^="voucherEmail-"]').siblings('.invalid-feedback');
                    invalidMail.toggleClass('d-block', !data.recipientMail.valid);
                    if (data.recipientMail.valid) {
                        form.find('.js-recipient-mail').text(data.recipientMail.value);
                    } else {
                        invalidMail.text(data.recipientMail.value);
                    }

                    if (data.voucherMsg.valid) {
                        form.find('.js-voucher-msg').text(data.voucherMsg.value ? data.voucherMsg.value : '');
                        form.find('.js-voucher-msg').closest('.row').toggleClass('d-none', !data.voucherMsg.value);
                    }

                    if (data.valid) {
                        // leave 'edit mode'
                        form.find('.evoucher-shipping-address').addClass('d-none');
                        form.find('.multi-shipping-summary').removeClass('d-none');
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    cancelSingleOnlineVoucherShipment: function () {
        $('.btn-cancel-online-voucher').on('click', function (e) {
            e.preventDefault();
            var parent = $(this).closest('.evoucher-block');

            var savedDataName = parent.find('.js-recipient-name').text();
            var savedDataEmail = parent.find('.js-recipient-mail').text();
            var savedDataMsg = parent.find('.js-voucher-msg').text();

            parent.find('input[name^="voucherTo-"]').val(savedDataName);
            parent.find('input[name^="voucherEmail-"]').val(savedDataEmail);
            parent.find('textarea[name^="voucherMsg-"]').val(savedDataMsg);

            // leave 'edit mode'
            parent.find('.evoucher-shipping-address').addClass('d-none');
            parent.find('.multi-shipping-summary').removeClass('d-none');
        });
    },

    selectShippingMethod: function () {
        var baseObj = this;

        $('.shipping-method-list').change(function () {
            var $shippingForm = $(this).parents('form');
            var methodID = $(':checked', this).val();
            var shipmentUUID = $shippingForm.find('[name=shipmentUUID]').val();
            var urlParams = addressHelpers.methods.getAddressFieldsFromUI($shippingForm);
            urlParams.shipmentUUID = shipmentUUID;
            urlParams.methodID = methodID;

            var url = $(this).data('select-shipping-method-url'); // CheckoutShippingServices-SelectShippingMethod

            if (baseObj.methods && baseObj.methods.selectShippingMethodAjax) {
                baseObj.methods.selectShippingMethodAjax(url, urlParams, $(this));
            } else {
                base.selectShippingMethodAjax(url, urlParams, $(this));
            }
        });
    },

    toggleMultiship: function () {
        $('input[name="usingMultiShipping"]').on('change', function () {
            $(this).siblings('label').addClass('disabled');
            const url = $(this).closest('form').attr('action');

            $.post(url, { usingMultiShip: this.checked }).done(response => {
                if (response.error) {
                    window.location.href = response.redirectUrl;
                } else {
                    // TODO: I know doing an AJAX call and then reloading is not ideal
                    window.location.reload();
                }
            });
        });
    },

    selectSingleShipping: base.selectSingleShipping,

    selectMultiShipping: base.selectMultiShipping,

    selectSingleShipAddress: function () {
        $('.single-shipping .addressSelector').on('change', function () {
            var form = $(this).parents('form')[0];
            var selectedOption = $('option:selected', this);

            var valisette = $('input[name$=_valisette]', form).prop('checked');
            var isGift = $('input[name$=_isGift]', form).prop('checked');
            var giftSender = $('input[name$=_giftSender]', form).val();
            var giftMessage = $('textarea[name$=_giftMessage]', form).val();
            selectedOption.data('valisette', valisette);
            selectedOption.data('isGift', isGift);
            selectedOption.data('giftSender', giftSender);
            selectedOption.data('giftMessage', giftMessage);

            var attrs = selectedOption.data();
            var shipmentUUID = selectedOption[0].value;
            var originalUUID = $('input[name=shipmentUUID]', form).val();
            var element;

            Object.keys(attrs).forEach(function (attr) {
                if (attr === 'isGift' || attr === 'valisette') {
                    $('[name$=' + attr + ']', form).prop('checked', attrs[attr]);
                    $('[name$=' + attr + ']', form).trigger('change');
                } else {
                    element = attr === 'countryCode' ? 'country' : attr;
                    $('[name$=' + element + ']', form).val(attrs[attr]);
                }
            });
            $('[name$=stateCode]', form).trigger('change');
            $('[name$=_country]', form).trigger('change');

            setLayout(attrs, form);

            if (shipmentUUID === 'new') {
                $(form).attr('data-address-mode', 'new');
                $(form).find('.shipping-address-block').removeClass('d-none');
            } else if (shipmentUUID === originalUUID) {
                $(form).attr('data-address-mode', 'shipment');
            } else if (shipmentUUID.indexOf('ab_') === 0) {
                $(form).attr('data-address-mode', 'customer');
            } else {
                $(form).attr('data-address-mode', 'edit');
            }
        });
    },

    handleAlphabeticalSortCountry: function () {
        const $countrySelect = $('select[name$="shippingAddress_addressFields_country"]');
        $countrySelect.each(function () {
            countrySelectHelper.handleAlphabeticalSort($(this));
        });
    },

    // TODO: edit selectMultiShipAddress to use setlayout
    // SFRA Function Extended with giftmessage and sender prefil
    selectMultiShipAddress: function () {
        var baseObj = this;

        $('.multi-shipping .addressSelector').on('change', function () {
            var form = $(this).closest('form');
            var selectedOption = $('option:selected', this);

            var isGift = $('input[name$=_isGift]', form).prop('checked');
            var valisette = $('input[name$=_valisette]', form).prop('checked');
            var giftSender = $('input[name$=_giftSender]', form).val();
            var giftMessage = $('textarea[name$=_giftMessage]', form).val();
            selectedOption.data('isGift', isGift);
            selectedOption.data('valisette', valisette);
            selectedOption.data('giftSender', giftSender);
            selectedOption.data('giftMessage', giftMessage);

            var attrs = selectedOption.data();
            var shipmentUUID = selectedOption[0].value;
            var originalUUID = $('input[name=shipmentUUID]', form).val();
            var pliUUID = $('input[name=productLineItemUUID]', form).val();
            var createNewShipmentScoped = baseObj.methods && baseObj.methods.createNewShipment ? baseObj.methods.createNewShipment : base.methods.createNewShipment;

            var element;
            Object.keys(attrs).forEach(function (attr) {
                if (attr === 'isGift' || attr === 'valisette') {
                    $('[name$=' + attr + ']', form).prop('checked', attrs[attr]);
                    $('[name$=' + attr + ']', form).trigger('change');
                } else {
                    element = attr === 'countryCode' ? 'country' : attr;
                    $('[name$=' + element + ']', form).val(attrs[attr]);
                }
            });

            if (shipmentUUID === 'new' && pliUUID) {
                var createShipmentUrl = $(this).attr('data-create-shipment-url');
                createNewShipmentScoped(createShipmentUrl, { productLineItemUUID: pliUUID })
                    .done(function (response) {
                        $.spinner().stop();
                        if (response.error) {
                            if (response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                            return;
                        }
                        $('body').trigger('checkout:updateCheckoutView',
                            {
                                order: response.order,
                                customer: response.customer,
                                options: { keepOpen: true }
                            }
                        );

                        $(form).attr('data-address-mode', 'new');
                    })
                    .fail(function () {
                        $.spinner().stop();
                    });
            } else if (shipmentUUID === originalUUID) {
                $('select[name$=stateCode]', form).trigger('change');
                $(form).attr('data-address-mode', 'shipment');
            } else if (shipmentUUID.indexOf('ab_') === 0) {
                var url = $(form).attr('action');
                var serializedData = $(form).find(":input:not([name='originalAddressID'])").serialize();

                createNewShipmentScoped(url, serializedData)
                    .done(function (response) {
                        $.spinner().stop();
                        if (response.error) {
                            if (response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                            return;
                        }
                        $('body').trigger('checkout:updateCheckoutView',
                            {
                                order: response.order,
                                customer: response.customer,
                                options: { keepOpen: true }
                            }
                        );

                        $(form).attr('data-address-mode', 'customer');
                        var $rootEl = $(form).closest('.shipping-content');
                        base.methods.editMultiShipAddress($rootEl);
                    })
                    .fail(function () {
                        $.spinner().stop();
                    });
            } else {
                var updatePLIShipmentUrl = $(form).attr('action');
                var serializedAddress = $(form).serialize();
                createNewShipmentScoped(updatePLIShipmentUrl, serializedAddress)
                    .done(function (response) {
                        $.spinner().stop();
                        if (response.error) {
                            if (response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                            return;
                        }
                        $('body').trigger('checkout:updateCheckoutView',
                            {
                                order: response.order,
                                customer: response.customer,
                                options: { keepOpen: true }
                            }
                        );

                        $(form).attr('data-address-mode', 'edit');
                    })
                    .fail(function () {
                        $.spinner().stop();
                    });
            }
        });
    },

    updateShippingList: function () {
        // Disable submit by enter key while still filling in the form.
        // Fixes bug by preventing submitting a form whilst doing an ajax call to fetch available shipping methods
        // This does not prevent accessibility, for a user can still use keyboard to navigate through by using tab and entering on the button itself.
        $(document).on('keydown', '.shipping-form', function (e) {
            return e.key !== 'Enter';
        });

        const $stateCodeSelect = $('select[name$="shippingAddress_addressFields_states_stateCode"]');
        const $postalCodeInput = $('input[name$="shippingAddress_addressFields_postalCode"]');
        const $countrySelect = $('select[name$="shippingAddress_addressFields_country"]');

        $stateCodeSelect
            .add($postalCodeInput)
            .add($countrySelect)
            .on('change', function (e) {
                var $currentForm = $(e.currentTarget.form);
                updateShippingMethodList($currentForm);
                $currentForm.find('.shipping-method-list').data('requestRunning', false);
            });
    },

    onShippingCompanyClick: function () {
        $('.js-shipping-company-checkbox').change(function () {
            $(this).attr('value', this.checked); // Necessary for method 'getAddressFromRequest' in 'shippingHelpers.js'

            const companyInfo = $(this).closest('.js-company-info');
            companyInfo.find('.js-company-fields').toggleClass('d-none', !this.checked);

            companyInfo.find('.js-company-name').prop('required', this.checked);
            companyInfo.find('.js-company-name').attr('aria-required', this.checked.toString());

            updateShippingMethodList($(this.form));
        });
    },

    editMultiShipInfo: function () {
        var baseObj = this;

        $('.btn-edit-multi-ship, .btn-enter-multi-ship').on('click', function (e) {
            e.preventDefault();
            var form = $(this).closest('form');

            var giftCheckbox = $('[name$=isGift]', form).prop('checked');
            if ((giftCheckbox && $('.gift-message', form).hasClass('d-none')) || (!giftCheckbox && !$('.gift-message', form).hasClass('d-none'))) {
                $('[name$=isGift]', form).trigger('change');
            }

            var valisetteCheckbox = $('[name$=valisette]', form).prop('checked') ? $('[name$=valisette]', form).trigger('change') : null;
            if (valisetteCheckbox) {
                $('[name$=valisette]', form).trigger('change');
            }

            if (baseObj.methods && baseObj.methods.editOrEnterMultiShipInfo) {
                baseObj.methods.editOrEnterMultiShipInfo($(this), 'edit');
            } else {
                editOrEnterMultiShipInfo($(this), 'edit');
            }
        });
    },

    saveMultiShipInfo: function () {
        var baseObj = this;

        $('.btn-save-multi-ship').on('click', function (e) {
            e.preventDefault();

            // Save address to checkoutAddressBook
            var form = $(this).closest('form');
            var $rootEl = $(this).closest('.shipping-content');
            var data = $(form).serialize();
            var url = $(form).attr('action');

            $rootEl.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: data
            })
                .done(function (response) {
                    formHelpers.clearPreviousErrors(form);
                    if (response.error) {
                        if (response.fieldErrors && response.fieldErrors.length) {
                            response.fieldErrors.forEach(function (error) {
                                if (Object.keys(error).length) {
                                    formHelpers.loadFormErrors(form, error);
                                }
                            });

                            // open form to make errors visible
                            $(form).find('.btn-show-details').trigger('click');
                        } else if (response.serverErrors && response.serverErrors.length) {
                            $.each(response.serverErrors, function (index, element) {
                                base.methods.createErrorNotification(element);
                            });
                        } else if (response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                        }
                    } else {
                        // Update UI from response
                        $('body').trigger('checkout:updateCheckoutView',
                            {
                                order: response.order,
                                customer: response.customer
                            }
                        );

                        if (baseObj.methods && baseObj.methods.viewMultishipAddress) {
                            baseObj.methods.viewMultishipAddress($rootEl);
                        } else {
                            base.methods.viewMultishipAddress($rootEl);
                        }
                    }

                    if (response.order && response.order.shippable) {
                        $('button.submit-shipping').attr('disabled', null);
                    }

                    $rootEl.spinner().stop();
                })
                .fail(function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }

                    $rootEl.spinner().stop();
                });

            return false;
        });
    }
};
